body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  /* FullCalendar */
  --fc-button-bg-color: #164233;
  --fc-button-border-color: #164233;
  --fc-button-hover-bg-color: #164233;
  --fc-button-hover-border-color: #164233;
  --fc-button-active-bg-color: #164233;
  --fc-button-active-border-color: #164233;
  --fc-event-bg-color: #164233;
  --fc-event-border-color: #164233;
  --fc-highlight-color: #eecf78;
}

